<template lang="pug">
.fixed-bottom-right.scroll-top(v-if="showButtonTop" @click="goTop")
    q-btn(
        fab
        icon="etabl:arrow_up"
        color="secondary"
        text-color="positive"
        background="primary")
</template>

<script>
import { scroll } from 'quasar';
import { ref, onMounted } from 'vue';

const { getScrollTarget, setVerticalScrollPosition } = scroll;

export default {
    setup() {
        const showButtonTop = ref(false);

        const goScroll = () => {
            if (pageYOffset < 200) {
                if (showButtonTop.value === true) {
                    showButtonTop.value = false;
                }
            } else {
                if (showButtonTop.value === false) {
                    showButtonTop.value = true;
                }
            }
        };

        const goTop = () => {
            const target = getScrollTarget(document.querySelector('#q-app'));
            setVerticalScrollPosition(target, 0, 300);
        };

        onMounted(() => {
            if (process.env.CLIENT) {
                window.addEventListener('scroll', goScroll);
            }
        });

        return {
            goTop,
            showButtonTop,
        };
    },
};
</script>

<style lang="scss" scoped>
.scroll-top {
    right: 30px;
    bottom: 100px;
    z-index: 3000;
}
</style>